import styled from 'styled-components'

interface SpinnerProps {
  size: number
  marginBottom?: number
  borderTopColor?: string
}

export const Spinner = styled.div<SpinnerProps>`
    width: ${(props) => props.size}rem; 
    height: ${(props) => props.size}rem;
    display: inline-block;
    border: 3px solid rgba(255, 255, 255, 0.3);
    border-radius: 50%;
    border-top-color: ${(props) => props.borderTopColor || '#FFFFFF'};
    animation: 1s spin ease-in-out infinite;
    margin-bottom: ${(props) => props.marginBottom || 0}rem; 
  }
  @keyframes spin{
    to{
      transform: rotate(360deg);
    }
  `
