import { Link } from 'gatsby'
import styled from 'styled-components'

export const StyledLearnMore = styled(Link)`
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  /* width: 10vw; */
  padding-right: 2rem;
  padding-left: 2rem;
  text-align: center;
  display: inline-block;
  margin-top: 1rem;
  margin-bottom: 1rem;

  background: rgba(255, 255, 255, 0.1);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(13px);
  -webkit-backdrop-filter: blur(13px);
  border-radius: 36px;
  border: 2px solid white;

  transition: 0.6s;
  overflow: hidden;
  &:focus {
    outline: 0;
  }
  &:hover {
    background: rgb(255, 255, 255);
    color: black;

    cursor: pointer;
  }
`
