import styled from 'styled-components'

export const H2 = styled.h2`
  color: white;
  font-family: 'Open Sans';
  font-weight: 400;
  line-height: 150%;

  @media (min-width: 200px) {
    font-size: 1.5rem;
    margin-bottom: 1rem;
    margin-top: 1rem;
  }
  @media (min-width: 750px) {
    font-size: 2rem;
    margin-bottom: 2rem;
    margin-top: 2rem;
  }

  @media (min-width: 1200px) {
    font-size: 2rem;
  }
`
