import React from 'react'
import styled from 'styled-components'

interface H2Props {
  icon?: any
  width?: string
  text?: string
  className?: string
  color?: string
}

export const StyledH2Component: React.FC<H2Props> = (props) => {
  return <StyledH2 {...props}>{props.text}</StyledH2>
}

const StyledH2 = styled.h2<H2Props>`
  color: ${(props) => (props.color ? props.color : '#ffffff')};
  font-family: 'Open Sans';
  font-size: 2.5rem;
  font-weight: 400;
  line-height: 100%;
  margin-bottom: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  &:before {
    content: '';
    width: ${(props) => (props.width ? props.width : null)};
    height: ${(props) => (props.width ? '3rem' : null)};
    background: url(${(props) => (props.icon ? props.icon : null)}) no-repeat;
    background-size: contain;

    float: left;
    /* margin: 10px 10px 0 0; */
  }

  @media (min-width: 200px) {
    font-size: 1rem;
  }

  @media (min-width: 750px) {
    font-size: 1.275rem;
  }

  @media (min-width: 1200px) {
    font-size: 1.3rem;
  }
`
