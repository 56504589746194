import styled from 'styled-components'

export const H3 = styled.h3`
  font-weight: 500;
  color: white;
  font-family: 'Open Sans', sans-serif;

  @media (min-width: 200px) {
    font-size: 1.275rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  @media (min-width: 750px) {
    font-size: 1.5rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  @media (min-width: 1200px) {
    font-size: 1.725rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
`
