import React from 'react'
import styled from 'styled-components'

interface CardProps {
  className?: string
}

export const GlassCard: React.FC<CardProps> = (props) => {
  return <StyledGlassCard {...props}>{props.children}</StyledGlassCard>
}

const StyledGlassCard = styled.div`
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(13px);
  -webkit-backdrop-filter: blur(13px);
  border-radius: 18px;
  border: 1px solid rgba(255, 255, 255, 0.18);

  @media (min-width: 200px) {
    padding: 1rem;
  }

  @media (min-width: 750px) {
    padding: 1.25rem;
  }
  @media (min-width: 1200px) {
    padding: 1.5rem;
  }
`
