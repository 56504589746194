import React from 'react'
import styled from 'styled-components'

interface Props {
  text: string
  color: string
  classname?: any
  filled?: boolean
}

export const CollectionButton: React.FC<Props> = (props) => {
  return <StyledButton {...props}>{props.text}</StyledButton>
}

const StyledButton = styled.button<Props>`
  background: ${(props) => (props.filled ? props.color : '#28282F')};
  border: 2px solid ${(props) => props.color};
`
