import React from 'react'
import styled from 'styled-components'

interface Props {
  title: string
  className?: any
}

export const BaseH1: React.FC<Props> = (props) => {
  return <StyledH1 {...props}>{props.title}</StyledH1>
}

export const StyledH1 = styled.h1<Props>`
  font-family: 'Roboto';
  color: #ffffff;
  font-weight: 400;
  line-height: 150%;

  @media (min-width: 200px) {
    font-size: 1.25rem;
  }

  @media (min-width: 750px) {
    font-size: 2rem;
  }

  @media (min-width: 1200px) {
    font-size: 2.5rem;
  }
`
