import styled from 'styled-components'

export const ParentSection = styled.section`
  display: grid;
  grid-template-rows: auto;
  height: 100%;
  width: 100%;
  justify-items: center;
  align-items: center;
  padding-top: 4rem;

  @media (min-width: 200px) {
    width: 100vw;
  }

  @media (min-width: 750px) {
  }

  @media (min-width: 1200px) {
    row-gap: 5rem;
  }
`

export const ChildSectionFullWidth = styled.section`
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-size: contain;
  overflow-x: hidden;
`

export const ChildSectionShortWidth = styled.section`
  position: relative;
  margin-bottom: 5rem;
  @media (min-width: 200px) {
    margin-top: 3rem;
    width: 95%;
  }
  @media (min-width: 750px) {
    padding-top: 2rem;
    width: 75%;
  }
  @media (min-width: 1200px) {
    max-width: 1440px;
    margin-right: auto;
    margin-left: auto;
    padding-right: 64px;
    padding-left: 64px;
  }
`
