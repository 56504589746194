import React from 'react'
import styled from 'styled-components'

interface Props {
  text: string
  children?: any
  secondary?: boolean
  passedFunction?: any
}

export const Button: React.FC<Props> = (props) => {
  return (
    <StyledButton {...props} onClick={props.passedFunction}>
      {props.text}
    </StyledButton>
  )
}

const StyledButton = styled.button<Props>`
  background: ${(props) => (props.secondary ? 'transparent' : '#fff201')};
  color: ${(props) => (props.secondary ? 'white' : 'black')};
  width: 12rem;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  font-weight: 600;

  &:hover {
    cursor: pointer;
    background: ${(props) => (props.secondary ? '#fff201' : '#00FFC2')};
  }
`
