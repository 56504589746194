import styled from 'styled-components'

export const H1 = styled.h1`
  color: white;
  font-family: 'Roboto';
  font-weight: 500;
  text-align: center;

  @media (min-width: 200px) {
    font-size: 2rem;
  }
  @media (min-width: 750px) {
    font-size: 2.5rem;
  }

  @media (min-width: 1200px) {
    font-size: 3rem;
  }
`
